/* animation*/
.reveal_animation {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
  transition-delay: 0s, 0s, 0s;
  opacity: 0;
  -webkit-transform: scale(0) translateY(30px);
  transform: scale(0.9) translateY(30px);
}
.reveal_animation.enter {
  transition: all 1s 0.25s ease-in-out;
  opacity: 1;
  -webkit-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

/* animation*/